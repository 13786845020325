import clickHigh from "../assets/Perc_MetronomeQuartz_hi.wav";
import clickLow from "../assets/Perc_MetronomeQuartz_lo.wav";
import React, {useEffect, useState} from "react";
import {useMetronome} from "../hooks/useMetronome";
import {Box, Button, Checkbox, Divider, FormControlLabel, Slider, Typography, useTheme} from "@mui/material";
import {Pause, PlayArrow} from "@mui/icons-material";
import {MetronomeDots} from "./MetronomeDots";
import {Song} from "../App";


function MetronomeLogic({useAccentSound, beastMode, activeBpm}: {
    useAccentSound: boolean,
    beastMode: boolean,
    activeBpm: number
}) {
    const {
        startMetronome,
        stopMetronome,
        isTicking,
        setBpm,
        beatsPerMeasure,
        setBeatsPerMeasure,
        beatNumber
    } = useMetronome({
        initialBpm: activeBpm,
        initialBeatsPerMeasure: 4,
        strongTickSoundSrc: useAccentSound ? clickHigh : clickLow,
        weakTickSoundSrc: clickLow
    });
    useEffect(() => {
        setBpm(beastMode ? activeBpm * 1.2 : activeBpm);
        if (isTicking) {
            stopMetronome();
            startMetronome();
        }
    }, [beastMode, isTicking, activeBpm]);
    return <>
        <MetronomeDots numberOfBeats={beatsPerMeasure} activeBeat={beatNumber - 1}/>
        <Button variant="outlined"
                color={isTicking ? "warning" : "success"}
                onClick={isTicking ? stopMetronome : startMetronome}
                startIcon={isTicking ? <Pause/> : <PlayArrow/>}>
            {isTicking ? "Pause" : "Start"}
        </Button></>;
}

export function MetronomeCard({activeSong}: {
    activeSong: Song,
}) {
    const theme = useTheme();
    const [activeBpm, setActiveBpm] = useState(activeSong.BPM);
    const [sliderValue, setSliderValue] = useState(activeSong.BPM);
    const [isCustomBpm, setIsCustomBpm] = useState(false);

    const [useTwoSounds, setUseTwoSounds] = useState(false);
    const [beastMode, setBestMode] = useState(false);
    useEffect(() => {
        setSliderValue(activeSong.BPM);
        setActiveBpm(activeSong.BPM);
        setIsCustomBpm(false);
    }, [activeSong]);
    return <Box sx={{
        display: 'flex', width: '100%',
        backgroundColor: '#333',
        border: '1px solid #666',
        borderRadius: '10px',
        padding: theme.spacing(3),
        flexDirection: 'column', gap: theme.spacing(2)
    }}>
        <Typography gutterBottom variant="h6" component={'div'} fontWeight={'700'}>
            {isCustomBpm ? "Custom" : `${activeSong.artist} - ${activeSong.title}`}
        </Typography>
        <Typography gutterBottom variant="h4" component={'div'} fontWeight={'700'}>
            {beastMode ? "BEAST MODE! 120% of " : ""}{sliderValue} BPM
        </Typography>
        <Slider
            value={sliderValue}
            onChange={(event, value) => {
                setSliderValue(value as number)
            }}
            onChangeCommitted={(event, value) => {
                setActiveBpm(value as number)
                setIsCustomBpm(true);
            }}
            aria-labelledby="slider-label"
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => value.toString()}
            step={1}
            min={40}
            max={220}
        />
        <Divider/>
        <MetronomeLogic activeBpm={activeBpm} useAccentSound={useTwoSounds}
                        beastMode={beastMode}/>
        <Divider/>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-around'}}>
            <FormControlLabel control={<Checkbox
                checked={useTwoSounds}
                onChange={() => setUseTwoSounds(!useTwoSounds)}
            />} label="Use accent sound"/>
            <FormControlLabel control={<Checkbox
                checked={beastMode}
                onChange={() => setBestMode(!beastMode)}
            />} label="Beast mode (Everything is 120%)"/>
        </Box>
    </Box>
}
