import {Box} from "@mui/material";

export function MetronomeDots(props: { activeBeat: number, numberOfBeats: any }) {
    const { activeBeat, numberOfBeats } = props;

    const dots = Array.from({ length: numberOfBeats }, (_, index) => (
        <Box
            key={index}
            sx={{
                width: `${100 / numberOfBeats}%`, // Set width to 100% divided by the number of beats
                height: '100%',
                borderRadius: '10px',
                backgroundColor: index === activeBeat ? 'white' : '#666',
                border: '1px solid #333',
            }}
        />
    ));

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                gap: '20px',
                height: '100px'
            }}
        >
            {dots}
        </Box>
    );
}
