import {Song} from "../App";
import {List, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@mui/material";

export const SongList = ({songs, onSongClicked}: { songs: Song[], onSongClicked: (song: Song) => void }) => {
    // Sort songs by artist
    const sortedSongs = songs
        .slice()
        .sort((a, b) => a.artist.localeCompare(b.artist));

    return (
        <List
            sx={{bgcolor: 'background.paper'}}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Pick a song
                </ListSubheader>
            }
        >
            {sortedSongs.map((song, index) => (
                <ListItemButton
                    onClick={() => onSongClicked(song)}
                    key={index}
                >

                    <ListItemIcon>
                        <strong
                            style={{
                                backgroundColor: "black",
                                color: "white",
                                borderRadius: "5px",
                                padding: "2px 5px",
                                marginRight: "5px",
                            }}
                        >
                            {song.BPM}
                        </strong>
                    </ListItemIcon>
                    <ListItemText primary={`${song.artist} - ${song.title}`}/>
                </ListItemButton>
            ))}
        </List>
    );
};
