import React, {useState} from 'react';
import './App.css';
import {MetronomeCard} from "./components/MetronomeCard";
import {SongList} from "./components/SongList";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Container, createTheme, CssBaseline, Grid, ThemeProvider} from "@mui/material";

export type Song = {
    artist: string;
    title: string;
    BPM: number;
};

const songs = [
    {artist: "Chateau", title: "Objemi Me", BPM: 110},
    {artist: "Crazy Lixx", title: "Silent Thunder", BPM: 144},
    {artist: "Dance with the Dead", title: "Sledge", BPM: 166},
    {artist: "Danger Danger", title: "I Still Think About You", BPM: 142},
    {artist: "Dokken", title: "Breaking The Chains", BPM: 124},
    {artist: "Firehouse", title: "All She Wrote", BPM: 120},
    {artist: "Ghost", title: "Witch Image", BPM: 120},
    {artist: "Ghost", title: "Square Hammer", BPM: 130},
    {artist: "Guns n' Roses", title: "Sweet Child O' Mine", BPM: 123},
    {artist: "In Flames", title: "Only For The Weak", BPM: 105},
    {artist: "Iron Maiden", title: "Wasted Years", BPM: 154},
    {artist: "Journey", title: "Separate Ways", BPM: 131},
    {artist: "Metallica", title: "Atlas, Rise!", BPM: 190},
    {artist: "Poison", title: "Fallen Angel", BPM: 148},
    {artist: "Powerwolf", title: "We Drink Your Blood", BPM: 112},
];

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


function App() {
    const [activeSong, setActiveSong] = useState<Song>(songs[0]);
    const CustomSpacer = <Grid item xs={0} md={3}/>
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <div className="App">
                <Container sx={{marginTop: {xs: 0, md: '25px'}}}>
                    <Grid
                        container
                        spacing={2}
                    >
                        {CustomSpacer}
                        <Grid item xs={12} md={6}>
                            <MetronomeCard activeSong={activeSong}/>
                        </Grid>
                        {CustomSpacer}
                        {CustomSpacer}
                        <Grid item xs={12} md={6}>
                            <SongList songs={songs} onSongClicked={(song) => {
                                setActiveSong(song);
                            }}/>
                        </Grid>
                        {CustomSpacer}
                    </Grid>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default App;
