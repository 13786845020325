import {useEffect, useState} from 'react';
import debounce from 'lodash.debounce';
import {useAudioPlayer} from "react-use-audio-player";

const FIRST_BEAT = 1;
const DEFAULT_BPM = 60;
const DEFAULT_BEATS_PER_MEASURE = 4;

interface MetronomeProps {
    initialBpm?: number;
    initialBeatsPerMeasure?: number;
    strongTickSoundSrc: string;
    weakTickSoundSrc: string;
}

export const useMetronome = ({
                                 initialBpm = DEFAULT_BPM,
                                 initialBeatsPerMeasure = DEFAULT_BEATS_PER_MEASURE,
                                 strongTickSoundSrc, weakTickSoundSrc
                             }: MetronomeProps) => {
    const [isTicking, setIsTicking] = useState(false);
    const [bpm, setBpm] = useState(initialBpm);
    const [beatNumber, setBeatNumber] = useState(0);
    const [beatsPerMeasure, setBeatsPerMeasure] = useState(initialBeatsPerMeasure);

    const {load: loadStrong, play: playStrong, stop: stopStrong} = useAudioPlayer();
    const {load: loadWeak, play: playWeak, stop: stopWeak} = useAudioPlayer();
    useEffect(() => {
        loadStrong(strongTickSoundSrc, {autoplay: false, html5: true});
    }, [loadStrong, strongTickSoundSrc]);
    useEffect(() => {
        loadWeak(weakTickSoundSrc, {autoplay: false, html5: true});
    }, [loadWeak, weakTickSoundSrc]);

    const handleChangeBeatsPerMeasure = debounce((newBeatsPerMeasure: any) => {
        if (newBeatsPerMeasure && !isNaN(newBeatsPerMeasure)) {
            setBeatsPerMeasure(parseInt(newBeatsPerMeasure, 10));
        } else {
            setBeatsPerMeasure(DEFAULT_BEATS_PER_MEASURE);
        }
    }, 500);

    const handleChangeBpm = debounce((newBpm: any) => {
        if (newBpm && !isNaN(newBpm)) {
            setBpm(parseInt(newBpm, 10));
        } else {
            setBpm(DEFAULT_BPM);
        }
    }, 500);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        let beat = FIRST_BEAT;

        const resetSounds = () => {
            stopStrong();
            stopWeak();
        };

        const tick = () => {
            setBeatNumber(beat);
            resetSounds();
            stopStrong();

            if (beat === FIRST_BEAT) {
                playStrong();
            } else {
                playWeak();
            }
            if (beat === beatsPerMeasure) {
                beat = FIRST_BEAT;
            } else {
                beat++;
            }
        };

        if (isTicking) {
            tick();
            interval = setInterval(tick, (60 / bpm) * 1000);
        }

        return () => clearInterval(interval);
    }, [isTicking, bpm, beatsPerMeasure, stopStrong, stopWeak, playStrong, playWeak]);

    return {
        beatsPerMeasure,
        bpm,
        isTicking,
        setBeatsPerMeasure: handleChangeBeatsPerMeasure,
        setBpm: handleChangeBpm,
        startMetronome: () => setIsTicking(true),
        stopMetronome: () => setIsTicking(false),
        beatNumber
    };
};
